<script>
import { texts } from "@/language/texts";
export default {
  name: 'ChatWindow',
  props: {
    color: {
      type: String,
      default: "#8B5CF6"
    },
    isShow: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      required: true
    },
    activeColor: {
      type: String,
      default: "#8B5CF6"
    },
    isInline: {
      type: Boolean,
      default: false
    },
    isTest: {
      type: Boolean,
      default: false
    },
    welcomeMessage: {
      type: String,
      default: 'Я всегда на связи и готов ответить! Чем я могу вам помочь?'
    },
    assistantName: {
      type: String,
      default: 'Андрей Ботаев'
    },
    assistantAvatar: {
      type: String,
      default: '@/assets/avatar.svg'
    },
    language: {
      type: String,
      default: 'ru'
    }
  },
  data() {
    return {
      userData: {
        email: null,
        name: null
      },
      messageToSend: null,
      inputMessage: null,
      showUserForm: false,
      messages: [],
      errors: {
        message: false,
        email: false,
        name: false
      },
      textLines: texts[this.language]
    }
  },
  mounted() {
    const userData = localStorage.getItem('userData');
    const messages = localStorage.getItem('messages');
    if (userData) {
      this.userData = JSON.parse(userData);
    }
    if (messages) {
      this.messages = JSON.parse(messages);
    }
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.$nextTick(() => {
          this.$refs.message.focus();
          this.scrollToBottom();
        })
      }
    },
    language() {
      this.textLines = texts[this.language];
      this.$nextTick(() => {
        this.scrollToBottom();
      })
    }
  },
  methods: {
    showTypingIndicator() {
      const typingElement = this.$refs.typing;
      typingElement.style.display = 'block';
      setTimeout(() => {
        typingElement.style.display = 'none';
      }, 2000);
    },
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
          (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },
    sendMessage() {
      this.errors = {message: false, email: false, name: false};
      if (this.inputMessage) this.messageToSend = this.inputMessage;

      if(this.isTest) {
        this.userData.email = 'test@lampabot.ru';
        this.userData.name = 'Пользователь';
        if(!localStorage.getItem('uid')) {
          localStorage.setItem('userData', JSON.stringify(this.userData));
          localStorage.setItem('uid', this.uuidv4());
        }
      }

      if (!this.showUserForm) {
        if (!this.messageToSend) {
          this.errors.message = true;
          return;
        }
      } else {
        if (!this.userData.name) {
          this.errors.name = true;
          return;
        }
        if (!this.userData.email || !this.isValidEmail(this.userData.email)) {
          this.errors.email = true;
          return;
        }
        localStorage.setItem('userData', JSON.stringify(this.userData));
        localStorage.setItem('uid', this.uuidv4());
      }

      this.inputMessage = null;
      if (this.userData.email && this.userData.name && this.messageToSend) {
        this.showUserForm = false;
        this.messages.push({
          type: 'user',
          message: this.messageToSend,
          date: new Date()
        })
        localStorage.setItem('messages', JSON.stringify(this.messages));
        this.$nextTick(() => {
          this.scrollToBottom();
        })
        this.sendMessageRequest(this.messageToSend, this.userData.email, this.userData.name);

      } else {
        this.showUserForm = true
        this.$nextTick(() => {
          this.$refs.name.focus();
        })
      }
    },
    async sendMessageRequest(message, email, sender) {
      const uuid = localStorage.getItem('uid');
      const data = {message, email, sender, uuid};

      try {
        const response = await fetch(process.env.VUE_APP_API_URL + this.slug, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    getTransparencyBackground(color) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);

      return `background-color: rgba(${r}, ${g}, ${b}, 0.15)`;
    },
    isSameSenderAsPrevious(index) {
      if (index === 0) return false;
      return this.messages[index].type === this.messages[index - 1].type;
    },
    isSameDayAsPrevious(index) {
      if (index === 0) return false;
      const previousDate = new Date(this.messages[index - 1].date);
      const currentDate = new Date(this.messages[index].date);

      return previousDate.getFullYear() === currentDate.getFullYear() &&
          previousDate.getMonth() === currentDate.getMonth() &&
          previousDate.getDate() === currentDate.getDate();
    },
    messageFromAssistant(message) {
      const typingElement = this.$refs.typing;
      typingElement.style.display = 'none';
      this.messages.push({
        type: 'assistant',
        message,
        date: new Date()
      });
      localStorage.setItem('messages', JSON.stringify(this.messages));
      this.$nextTick(() => {
        this.scrollToBottom();
      })
    },
    scrollToBottom() {
      const container = this.$refs.chatWindow;
      container.scrollTop = container.scrollHeight;
    }
  }
}
</script>

<template>
  <div class="chat-component" :class="!isInline ? 'bottom-chat' : ''" v-show="isShow">
    <div class="online-chat position-relative z-1" :style="isTest ? 'border: 0; max-width: initial;' : ''">
      <div class="chat-content" :style="isTest ? 'box-shadow: initial;' : ''">
        <div class="chat-title" v-if="!isTest">
          {{ textLines['online-chat'] }}
        </div>
        <div class="chat-title" v-else>
          Тестирование чат-бота
        </div>
        <div class="chat-window" ref="chatWindow">
          <div class="welcome-message d-flex">
            <div class="avatar" :style="'background: url(\'' + assistantAvatar +'\') top center'"></div>
            <div class="message-data">
              <div class="bot-message">
                <div class="d-flex align-items-center">
                  <div class="online-status"></div>
                  <div class="name">{{ assistantName }}</div>
                </div>
                <div class="message-box">
                  {{ welcomeMessage }}
                </div>
              </div>
              <div v-if="showUserForm" class="user-form message-box">
                <div class="name">{{ textLines['show-contacts-data'] }}</div>
                <div class="input-container">
                  <div>
                    <input type="text" class="chat-input" :placeholder="textLines['enter-your-name']" v-model="userData.name" :class="errors.name ? 'red-focus' : ''" ref="name" @keydown.enter.stop="sendMessage"/>
                  </div>
                  <div class="mt-10">
                    <input type="text" class="chat-input" :placeholder="textLines['enter-your-email']" v-model="userData.email" :class="errors.email ? 'red-focus' : ''" @keydown.enter.stop="sendMessage"/>
                  </div>
                  <button class="btn violet-button mt-10 ms-0" @click.stop="sendMessage"><span>{{ textLines['send'] }}</span></button>
                </div>
              </div>
            </div>
          </div>
          <div class="messages">
            <div v-for="(message, index) in messages" :key="index">
              <div v-if="!isSameDayAsPrevious(index) || index === 0">
                <div class="date-divider">{{ new Date(message.date).toLocaleDateString() }}</div>
              </div>
              <div :class="message.type === 'user' ? 'user-message' : 'bot-message'" class="d-flex">
                <div class="avatar" v-if="message.type !== 'user'"  :style="'background: url(\'' + assistantAvatar +'\')'"></div>
                <div class="message-data">
                  <div v-if="message.type === 'user'">
                    <div class="name" v-if="!isSameSenderAsPrevious(index)">{{ userData.name }}</div>
                    <div class="message-box" :style="getTransparencyBackground(color)">
                      {{ message.message }}
                    </div>
                  </div>
                  <div v-else>
                    <div class="d-flex align-items-center">
                      <div class="online-status"></div>
                      <div class="name">{{ assistantName }}</div>
                    </div>
                    <div class="message-box">
                      {{ message.message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="typing" ref="typing">{{ textLines['agent-is-typing'] }}</div>
        <div class="chat-actions" :class="showUserForm ? 'disabled' : ''">
          <input type="text" :placeholder="textLines['enter-message']" v-model="inputMessage" :disabled="showUserForm" @keydown.enter="sendMessage" :class="errors.message ? 'red-focus' : ''" ref="message">
          <button class="btn violet-button" @click.stop="sendMessage" :disabled="showUserForm"><span>{{ textLines['send'] }}</span></button>
        </div>
      </div>
    </div>
    <div class="bottom-shadow" v-if="!isTest"></div>
  </div>
</template>

<style scoped>
.online-chat {
  color: #242424;
  font-family: 'Roboto', sans-serif;
  border-radius: 24px;
  margin-top: 105px;
  border: 16px solid rgba(255, 255, 255, .3);
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.chat-content {
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  background-clip: padding-box;
  background-color: white;
  box-shadow: 0 0 44px 0px rgba(0, 0, 0, 0.25);
}

.chat-content:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;
  background-image: linear-gradient(180.00deg, rgb(139, 92, 246), rgb(252, 195, 48));
  border-radius: 8px;
}

.typing {
  position: absolute;
  color: v-bind('color');
  opacity: 0.6;
  font-size: 12px;
  margin-top: -25px;
  margin-left: 25px;
  display: none;
}

.chat-title {
  padding: 15px 20px;
  background-color: v-bind('color');
  color: #fff;
  font-size: 16px;
  box-shadow: 0 4px 25.4px 0 rgba(0, 0, 0, 0.25);
  position: relative;
}

.chat-window {
  height: 400px;
  overflow-y: auto;
  padding: 30px;
  background-color: #fff;
}

.d-flex {
  display: flex;
}

.chat-actions {
  box-sizing: border-box;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: 0 -4px 81.8px 0 rgba(0, 0, 0, 0.09);
  background: rgb(255, 255, 255);
  padding: 11px 12px;
  display: flex;
  align-items: center;
}

.chat-actions input, .input-container input {
  box-sizing: border-box;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 7px;
  background: rgb(255, 255, 255);
  height: 36px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  color: #555;
}

.red-focus {
  border-color: #FF0000 !important;
}

.chat-actions .btn, .input-container button {
  height: 36px;
  padding: 6px 12px;
  margin-left: 12px;
}

.violet-button {
  border-radius: 5px;
  background: v-bind('color');
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 15px 20px;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.avatar {
  border: 4px solid v-bind('color');
  border-radius: 50%;
  box-shadow: inset 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  width: 62px;
  height: 62px;
  overflow: hidden;
  background-size: cover !important;
}

.avatar img {
}

.online-status {
  width: 12px;
  height: 12px;
  background-color: #8CECA7;
  border-radius: 50%;
  margin-right: 10px;
}

.name {
  font-size: 16px;
  font-weight: 700;
  color: #242424;
}

.message-data {
  margin-left: 24px;
}

.violet-button:hover {
  background: v-bind('activeColor');
  color: #fff;
}

.violet-button span {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.message-box {
  background-color: #F3F3F3;
  padding: 15px 15px;
  margin-top: 10px;
  line-height: 20px;
  max-width: 320px;
  border-radius: 13px;
}

.align-items-center {
  align-items: center;
}

.user-form {
  margin-top: 20px;
  line-height: 22px;
}

.input-container {
  margin-top: 10px;
}

.bot-message {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.ms-0 {
  margin-left: 0 !important
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled input, .disabled button {
  cursor: not-allowed;
}

input:focus {
  outline-color: v-bind('color') !important;
}

.bottom-shadow {
  filter: blur(120px);
  background: rgb(0, 0, 0);
  opacity: 0.15;
  max-width: 484px;
  width: 100%;
  height: 142px;
  position: absolute;
  left: 50%;
  margin-top: -90px;
  transform: translateX(-50%);
}

.position-relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.messages {
  margin-top: 20px;
}

.user-message {
  justify-content: flex-end;
  margin-bottom: 25px;
}

.user-message .name {
  text-align: right;
}

.date-divider {
  text-align: center;
  color: #888;
  font-size: 12px;
  padding: 15px;
}

.bottom-chat {
  position: fixed;
  z-index: 10;
  bottom: 100px;
  right: 20px;
}

.avatar img {
  width: 60px;
}

@media (max-width: 768px) {
  .message-box {
    max-width: 183px;
  }
}
</style>
